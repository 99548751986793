import React from 'react'
import { Paper } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function Accordions(props) {

  return (
    <Paper elevation={`${props.elevation}`} className="accordion flex mb-8">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <h6 className="text-blue-700 p-3">{props.title}</h6>
        </AccordionSummary>
        <AccordionDetails>
            <p>{props.description}</p>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}
