import React from "react"
import { Container, Grid } from "@material-ui/core"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

export default function Intro(props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "20%",
  })

  var backgroundImage = {
    backgroundImage: `url(${props.img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <Container>
      <Grid container className="intro">
        <Grid
          item
          md={6}
          sm={12}
          className="flex justify-center flex-col intro-text md:px-20"
        >
          <motion.div
            className="fade-in"
            ref={ref}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ delay: 0, duration: 0.5 }}
          >
            <h1 className="text-orange">{props.title}</h1>
            <h6>{props.text}</h6>
            {props.buttonLink ? (
              <a
                class="blue-button mt-10 inline-flex"
                href={props.buttonLink}
                target="_blank"
                rel="noreferrer"
              >
                <button
                  class="MuiButtonBase-root MuiButton-root MuiButton-text"
                  tabindex="0"
                  type="button"
                >
                  <span class="MuiButton-label">{props.buttonTitle}</span>
                </button>
              </a>
            ) : null}
          </motion.div>
        </Grid>
        {props.img ? (
          <motion.div
            className="fade-in"
            ref={ref}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ delay: 0, duration: 0.6 }}
          >
            <Grid
              item
              md={6}
              sm={12}
              className="intro-img"
              style={backgroundImage}
            />
          </motion.div>
        ) : (
          ""
        )}
      </Grid>
    </Container>
  )
}
