import React from 'react'
import { Container, Grid, Button } from '@material-ui/core'
import { Link } from 'gatsby'

export default function Parallax(props) {

    var backgroundImage = {
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${props.image})`
      };

    return (
        <div className="parallax bg-blue-900" style={ backgroundImage }>
            <Container>
                <Grid container className={`${props.order === 'right' ? 'flex-row' : 'flex-row-reverse' }`}>
                    <Grid item md={6} sm={12} className="parallax-text white-text text-center md:text-left mx-auto md:mx-0">
                        <h2 dangerouslySetInnerHTML={{__html: `${props.title}`}} />
                        {props.link ?
                            <Link to={props.link}><Button>{props.button}</Button></Link> : 
                        '' }
                    </Grid>
                    {props.homeImg ? 
                    <Grid item md={6} sm={12} className="parallax-text white-text">
                        <img src={props.homeImg} alt="MARATHON
              MORTGAGE CORP." />
                    </Grid> 
                    : '' }
                </Grid>
            </Container>
        </div>
    )
}