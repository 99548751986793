import React from "react"
import { graphql } from "gatsby"
import { Container, Grid } from "@material-ui/core"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Intro from "../components/website/Intro"
import BlueSection from "../components/website/BlueSection"
import Parallax from "../components/website/Parallax"
import Accordion from "../components/accordions/borrowers"

import PeaceMind from "../images/borrowers/peace-of-mind.jpg"
import Lightbulb from "../images/borrowers/our-promises.jpg"
import Footer from "../images/borrowers/borrowers-cta.jpeg"
import blueBG from "../images/borrowers/borrowers-background.svg"
import Locations from "../components/locations/index"

export default function Borrowers({ data }) {
  return (
    <Layout>
      <SEO title="Borrowers" />
      {data.allStrapiBorrowers.edges.map(({ node }) => (
        <>
          <Intro
            key={node.borrowers_intro.id}
            title={node.borrowers_intro.intro_title}
            text={node.borrowers_intro.intro_description}
            img={node.borrowers_intro.Intro_hero.publicURL}
          />
          <BlueSection
            key={node.borrowers_bluesection.id}
            title={node.borrowers_bluesection.Bluesection_title}
            description={
              node.borrowers_bluesection.bluesection_title_description
            }
            order="right"
            intro={true}
            background={blueBG}
          />
          <div className="my-32">
            <Container>
              <Grid
                container
                className="flex items-center flex-col-reverse md:flex-row"
              >
                <Grid item md={6}>
                  <h5 className="text-blue-500">Peace of mind</h5>
                  <h4 className="text-orange">Our Marathon commitment.</h4>
                  <p>Unlocking the door to your new home.</p>
                  <div className="attribute">
                    <h6 className="text-blue-800">Support</h6>
                    <p>
                      In concert with your mortgage broker, you will be guided
                      through all of the steps. We offer sound advice and there
                      are no surprises.
                    </p>
                  </div>
                  <div className="attribute">
                    <h6 className="text-blue-800">Trust</h6>
                    <p>
                      We are open and transparent – from our great rates to the
                      application process … we have nothing to hide.
                    </p>
                  </div>
                  <div className="attribute">
                    <h6 className="text-blue-800">Efficiency</h6>
                    <p>
                      Timing is everything. We offer quick turnaround service
                      for our borrowers.
                    </p>
                  </div>
                </Grid>
                <Grid item md={6} className="flex justify-center">
                  <img
                    src={PeaceMind}
                    className="border-2 mb-20"
                    alt="MARATHON
MORTGAGE CORP."
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="blue-section white-text">
            <BlueSection
              key={node.blusection_2.id}
              smalltitle={node.blusection_2.bluesection_smalltitle}
              title={node.blusection_2.Bluesection_title}
              description={node.blusection_2.bluesection_title_description}
              order="right"
              component={<Locations />}
            />
          </div>
          <div className="my-32">
            <Container>
              <Grid container className="flex items-center">
                <Grid item md={6} className="flex justify-center">
                  <img
                    src={Lightbulb}
                    alt="MARATHON
MORTGAGE CORP."
                  />
                </Grid>
                <Grid item md={6}>
                  <Accordion />
                </Grid>
              </Grid>
            </Container>
          </div>
          <Parallax
            title="Try our maximum mortgage calculator."
            link="/calculators#calculate"
            button="let's talk numbers"
            order="right"
            image={Footer}
          />
        </>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query Borrowers {
    allStrapiBorrowers {
      edges {
        node {
          borrowers_intro {
            id
            intro_title
            intro_description
            intro_color
            Intro_hero {
              publicURL
            }
          }
          borrowers_bluesection {
            id
            Bluesection_title
            bluesection_title_description
          }
          blusection_2 {
            id
            Bluesection_title
            bluesection_smalltitle
            bluesection_title_description
          }
        }
      }
    }
  }
`
