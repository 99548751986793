import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Accordion from "../website/accordion"

class BorrowerAccordion extends React.Component {

    render () {    

    return (
      <>
        {this.props.accordions.map((accordion) => {
          return <Accordion key={accordion.id}
                title={accordion.accordion_title}
                description={accordion.accordion_description}
            />
        })}
      </>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
    query {
        strapiBorrowers {
          borrowers_accordion {
            id
            accordion_title
            accordion_description
          }
        }
      }      
    `}
    render={(data) => (
      <BorrowerAccordion accordions={data.strapiBorrowers.borrowers_accordion} />
    )}
  />
)
